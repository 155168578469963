.header-title {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  color: white;
  font-size: 18px;
  margin-left: 10px;
}

.menu-icon {
  display: inline-block;
  color: white;
  float: right;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  margin: 0 10px;
}

.logout {
  float: right;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  margin: 0 10px;
}

.header-bar {
  background-color: #062F4F;
  width: 100%;
  height: 50px;
  padding: 4px 0;
}

.header-icon {
  display: inline-block;
  color: white;
  float: right;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
}

.history {
  cursor: pointer;
}